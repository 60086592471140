import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, css, up, down } from '@xstyled/styled-components'
import { Text } from '../components/text'
import SubHeader from '../components/sub-header'
import Button from '../components/button'

import nick from '../images/nick-telson.webp'
import andrew from '../images/andrew-webster.webp'
import moon from '../images/crescent-moon.svg'
import hvCircle from '../images/hv-circle-bg.svg'
import squiggle from '../images/blue-squiggle-horizontal.svg'
import { navigate } from 'gatsby'

const Profile = styled(Col)`
  font-size: 1.3em;
  line-height: 1.5em;
  text-align: center;
`

const ProfileName = styled(Box)`
  font-family: heading;
  font-size: 2.5em;
  margin: 5;
  text-align: center;
`

const ProfileSection = styled(Box)`
  margin-top: 5;
`

const ProfileSectionTitle = styled(Box)`
  font-family: heading;
`

const BlueSquiggle = styled(Box)`
  display: none;

  ${down(
    'md',
    css`
      display: block;
      background-image: url(${squiggle});
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 5;
      height: 30px;
      width: 100%;
    `
  )}
`

const YellowMoonBox = styled(Box)`
  background-color: yellow;
  margin-top: 5;
  padding: 5 0 5;

  ${up(
    'md',
    css`
      background-image: url(${moon});
      background-repeat: no-repeat;
      background-position: right;
    `
  )}
`

const HvCircleBox = styled(Box)`
  ${up(
    'xs',
    css`
      padding: 0 3;
    `
  )}

  ${up(
    'md',
    css`
      padding-bottom: 6;
      background-image: url(${hvCircle});
      background-repeat: no-repeat;
      background-position: -200px;
    `
  )}
`

const JoinBox = styled(Box)`
  margin: 5 0;
  text-align: center;
`

const JoinCta = styled(Text)`
  font-size: 1.8em;
`

const JoinButton = styled(Button)`
  margin: 5 auto;
`

const AboutUs = () => (
  <>
    <SEO title="About Us" />
    <Layout>
      <PageHeader>About Us</PageHeader>
      <HvCircleBox pt="5">
        <Container>
          <Row>
            <Col col={{ lg: 6 / 12 }} ml={{ lg: '50%' }}>
              <SubHeader mb="5">Horseplay Ventures</SubHeader>
              <Text>
                We set up Horseplay Ventures because, to put it bluntly - we
                love the startup world, and we love founding companies.
              </Text>

              <Text>
                We want to elevate the startup community to come up with the
                next best thing, all while bringing together the brightest new
                businesses and founders with the biggest and best network of
                vetted Angel Investors in the UK and beyond.
              </Text>

              <Text>
                But we also want to have some fun while we do it. Our own
                startup journey was a rollercoaster, and born from a tonne of
                hard work, tears and a dash of luck here and there, eventually
                growing our simple idea into a multi-million pound exit. We want
                to be there on the next rollercoaster rides alongside all our
                founders, helping them, and our investors, achieve life-changing
                exits.
              </Text>

              <Text>We are Horseplay Ventures.</Text>
            </Col>
          </Row>
        </Container>
      </HvCircleBox>

      <YellowMoonBox>
        <Container>
          <Row>
            <Col col={{ lg: 8 / 12 }}>
              <SubHeader textAlign="left" mb="5">
                DesignMyNight
              </SubHeader>

              <Text>
                Started in December 2010, we set out to disrupt the bar and
                restaurant online discovery scene in London.
              </Text>

              <Text>
                After a few years organically growing the site, we took on £500K
                of angel investment and pivoted to become a B2B software company
                too.
              </Text>

              <Text>
                We built a reservations platform, Collins; an event ticketing
                platform, Tonic; and an e-vouchering platform, Soda.
              </Text>
              <Text>
                Over the next 5 years we grew all arms of the business,
                attracting over 250 million page views on DesignMyNight, over
                £6m worth of ARR for the software arm and £200m transactional
                revenue.
              </Text>

              <Text>
                We grew the business to 65 employees without further investment
                and had a successful exit in November 2017 for over £25 million
                to a leading UK software company.
              </Text>
            </Col>
          </Row>
        </Container>
      </YellowMoonBox>

      <Container my={{ xs: 5, md: 5, lg: 6 }}>
        <Row>
          <Profile col={{ xs: 1, md: 4 / 8, lg: 4 / 12 }} ml={{ lg: '8.33%' }}>
            <img
              src={nick}
              width={{ xs: '183px', md: '200px', lg: '282px' }}
              alt="Nick Telson"
            />
            <ProfileName>Nick</ProfileName>
            <Text>
              Nick has a passion for languages, brand building, sales and
              marketing…and Tottenham Hotspur. He was one half of the
              DesignMyNight founding team and led the software sales and
              marketing effort.
            </Text>
            <ProfileSection>
              <ProfileSectionTitle>Most Admired Company</ProfileSectionTitle>
              Just Eat
            </ProfileSection>
            <ProfileSection>
              <ProfileSectionTitle>Favourite Book</ProfileSectionTitle>
              Shoe Dog
            </ProfileSection>
            <ProfileSection>
              <ProfileSectionTitle>Most Admired Founder</ProfileSectionTitle>
              Steve Jobs
            </ProfileSection>
            <ProfileSection>
              <ProfileSectionTitle>One Piece of Advice</ProfileSectionTitle>
              Try to do everything you can to dissuade yourself that your idea
              is a good one, before you do it. If you still feel positive after
              that, go for it!
            </ProfileSection>
          </Profile>

          <BlueSquiggle />

          <Profile
            col={{ xs: 1, md: 4 / 8, lg: 4 / 12 }}
            ml={{ lg: '16.66%' }}
            mt={{ xs: 5, md: 0 }}
            textAlign="center"
          >
            <img
              src={andrew}
              width={{ xs: '183px', md: '200px', lg: '282px' }}
              alt="Andrew Webster"
            />
            <ProfileName>Andrew</ProfileName>
            <Text>
              Andrew loves building brand strategies and getting underneath the
              figures. You’ll find him either on Excel or at Fratton Park. The
              other half of the DesignMyNight founding team, he led the product,
              strategy and finance effort.
            </Text>
            <ProfileSection>
              <ProfileSectionTitle>Most Admired Company</ProfileSectionTitle>
              Shopify
            </ProfileSection>
            <ProfileSection>
              <ProfileSectionTitle>Favourite Book</ProfileSectionTitle>
              The Snowball: Warren Buffett and the Business of Life
            </ProfileSection>
            <ProfileSection>
              <ProfileSectionTitle>Most Admired Founder</ProfileSectionTitle>
              Tobias Lütke
            </ProfileSection>
            <ProfileSection>
              <ProfileSectionTitle>One Piece of Advice</ProfileSectionTitle>
              Trust the gut.
            </ProfileSection>
          </Profile>
        </Row>
      </Container>

    </Layout>
  </>
)

export default AboutUs
